import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {map, Observable, of, tap, throwError} from 'rxjs';
import {environment} from '../environments/environment';
import {Discount, EventType, Fee, Order, OrderItem, Shop, Venue} from '../types';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private shopId!: string;

    constructor(private http: HttpClient) {
    }

    public postOrder(): Observable<PostOrderResponse> {
        return this.http.post<PostOrderResponse>(`${environment.API_URL}/seatedapi/v1.0/public/order`, {}, {
            withCredentials: true,
            headers: new HttpHeaders().set('X-TF-SHOPGUID', this.shopId)
        });
    }

    public postOrderItemsBestSeatAvailable(orderId: string, body: any): Observable<PostOrderItemResponse[]> {
        return this.http.post<PostOrderItemResponse[]>(`${environment.API_URL}/seatedapi/v1.0/public/order/${orderId}/item/best-seat-available`, body, {
            withCredentials: true,
            headers: new HttpHeaders().set('X-TF-SHOPGUID', this.shopId)
        });
    }

    public postOrderItems(orderId: string, body: any): Observable<PostOrderItemResponse[]> {
        return this.http.post<PostOrderItemResponse[]>(`${environment.API_URL}/seatedapi/v1.0/public/order/${orderId}/item`, body, {
            withCredentials: true,
            headers: new HttpHeaders().set('X-TF-SHOPGUID', this.shopId)
        });
    }

    public getOrder(orderId: string): Observable<Order> {
        return this.http.get<Order>(`${environment.API_URL}/seatedapi/v1.0/public/order/${orderId}`, {
            withCredentials: true,
            headers: new HttpHeaders().set('X-TF-SHOPGUID', this.shopId),
            params: new HttpParams()
                .set('order[orderItems]', true)
                .set('order[upsellOptions]', true)
        }).pipe(
            map((order: ResponseBody) => {

                const fees: Map<string, Fee> = new Map<string, Fee>();

                order.orderItems.forEach((orderItem: ResponseBody) => {
                    orderItem.additionalCosts.forEach((fee: ResponseBody) => {
                        if (!fees.has(fee.costItem)) {
                            fees.set(fee.costItem, {label: fee.label, amount: fee.amount});
                        } else {
                            const _fee = fees.get(fee.costItem);
                            fees.set(fee.costItem, {label: fee.label, amount: _fee!.amount + fee.amount});
                        }
                    });
                });

                order.additionalCosts.forEach((fee: ResponseBody) => {
                    fees.set(fee.costItem, {label: fee.label, amount: fee.amount});
                });

                const discounts: Map<string, Discount> = new Map<string, Discount>();
                order.discounts.forEach((discount: ResponseBody) => {
                    discounts.set(discount.id, {label: discount.name, amount: discount.amount});
                });

                return <Order>{
                    id: order.id,
                    expireAt: order.expireAt,
                    price: order.totalPrice,
                    consents: order.consents.map((consent: ResponseBody) => {
                        return {
                            id: consent.id,
                            isRequired: consent.isRequired,
                            name: consent.name,
                            resource: consent.resource?.link
                        }
                    }),
                    orderItems: order.orderItems.sort((a: ResponseBody, b: ResponseBody) => {
                        if (a.product) {
                            return 1;
                        } else {
                            return -1;
                        }
                    }).map((orderItem: ResponseBody) => {
                        return {
                            id: orderItem.id,
                            name: orderItem.ticket?.name ?? orderItem.subscription?.name ?? orderItem.product?.name,
                            price: orderItem.price
                        }
                    }).sort((a: ResponseBody, b: ResponseBody) => {
                        if (a.name > b.name) {
                            return 1;
                        } else {
                            return -1;
                        }
                    }),
                    fees: fees,
                    discounts: discounts,
                    upsellOptions: order.upsellOptions.map((upsellOption: ResponseBody) => {
                        return {
                            id: upsellOption.id,
                            name: upsellOption.name,
                            description: upsellOption.description,
                            upsellOptionTypes: upsellOption.types.map((upsellOptionType: ResponseBody) => {
                                return {
                                    id: upsellOptionType.id,
                                    name: upsellOptionType.name,
                                    imageUrl: upsellOptionType.resource,
                                    price: upsellOptionType.priceItem.price,
                                }
                            })
                        }
                    })
                }
            })
        );
    }

    public deleteOrder(orderId: string): Observable<void> {
        return this.http.delete<void>(`${environment.API_URL}/seatedapi/v1.0/public/order/${orderId}`, {
            withCredentials: true,
            headers: new HttpHeaders().set('X-TF-SHOPGUID', this.shopId)
        });
    }

    public getPaymentMethods(orderId: string): Observable<GetPaymentMethodResponse[]> {
        return this.http.get<GetPaymentMethodResponse[]>(`${environment.API_URL}/seatedapi/v1.0/public/payment/method?paymentMethod`, {
            withCredentials: true,
            headers: new HttpHeaders().set('X-TF-SHOPGUID', this.shopId),
            params: new HttpParams().set('paymentMethod[orderId]', orderId)
        });
    }

    public getVenue(venueId: string, eventId: string, eventType: EventType): Observable<Venue> {
        let httpParams = new HttpParams()
            .set('depth', 3);

        if (eventType === 'EVENT') {
            httpParams = httpParams.set('scope[eventId]', eventId)
        } else if (eventType === 'SUBSCRIPTION_TYPE') {
            httpParams = httpParams.set('scope[subscriptionTypeId]', eventId)
        }

        return this.http.get<Venue>(`${environment.API_URL}/seatedapi/v1.0/public/shop/venue/${venueId}`, {
            withCredentials: true,
            headers: new HttpHeaders().set('X-TF-SHOPGUID', this.shopId),
            params: httpParams
        }).pipe(
            map((venue: any) => {
                return {
                    id: venue.id,
                    name: venue.name,
                    imageUrl: venue.imageUrl,
                    rings: venue.rings.map((ring: any) => {
                        return {
                            id: ring.id,
                            name: ring.name,
                            sections: ring.sections.map((section: any) => {
                                const minX = Math.min(...section.seats.map((seat: any) => seat.split(',')[3]));
                                const minY = Math.min(...section.seats.map((seat: any) => seat.split(',')[4]));

                                return {
                                    id: section.id,
                                    name: section.name,
                                    groupId: section.groupId,
                                    type: section.type,
                                    seats: section.seats.map((seatInfo: string) => {
                                        const seatDetails = seatInfo.split(',');
                                        return {
                                            id: seatDetails[0],
                                            row: seatDetails[1],
                                            seat: seatDetails[2],
                                            x: +seatDetails[3] - minX,
                                            y: +seatDetails[4] - minY,
                                            state: 'TAKEN',
                                        };
                                    })
                                }
                            })
                        }
                    }),
                    stageType: venue.stageType
                }
            })
        );
    }

    public getInSale(shopId: string): Observable<any> {
        return this.http.get<any>(`${environment.API_URL}/seatedapi/v1.0/public/shop/${shopId}/insale`, {
            withCredentials: true,
            headers: new HttpHeaders().set('X-TF-SHOPGUID', this.shopId)
        });
    }

    public getSectionAvailability(venueId: string, sectionId: string, eventId: string, eventType: EventType): Observable<string[]> {
        let httpParams = new HttpParams();

        if (eventType === 'EVENT') {
            httpParams = httpParams.set('scope[eventId]', eventId)
        } else if (eventType === 'SUBSCRIPTION_TYPE') {
            httpParams = httpParams.set('scope[subscriptionTypeId]', eventId)
        }

        return this.http.get<string[]>(`${environment.API_URL}/seatedapi/v1.0/public/shop/venue/${venueId}/section/${sectionId}`, {
            withCredentials: true,
            headers: new HttpHeaders().set('X-TF-SHOPGUID', this.shopId),
            params: httpParams
        });
    }

    public getSectionStatus(venueId: string, eventId: string, eventType: EventType): Observable<any> {
        let httpParams = new HttpParams();

        if (eventType === 'EVENT') {
            httpParams = httpParams.set('scope[eventId]', eventId)
        } else if (eventType === 'SUBSCRIPTION_TYPE') {
            httpParams = httpParams.set('scope[subscriptionTypeId]', eventId)
        }

        return this.http.get<any>(`${environment.API_URL}/seatedapi/v1.0/public/shop/venue/${venueId}/status`, {
            withCredentials: true,
            headers: new HttpHeaders().set('X-TF-SHOPGUID', this.shopId),
            params: httpParams
        });
    }

    public getShop(slug: string): Observable<Shop> {
        return this.http.get<Shop>(`${environment.API_URL}/seatedapi/v1.0/public/shop/${slug}?depth=5&event[venue]=true&subscriptionType[venue]=true`).pipe(
            tap((shop: any) => this.shopId = shop.id),
            map((shop: any) => {

                let events = [];
                let subscriptions = [];

                if (shop.shopPages.length > 0) {
                    events = shop.shopPages[0].shopEvents.map((event: any) => ({
                        type: 'EVENT',
                        id: event.event.id,
                        slug: event.event.id,
                        name: event.event.name,
                        date: event.event.startAt,
                        description: event.event.description,
                        venue: {
                            id: event.event.venue?.id,
                            name: event.event.venue?.name,
                            city: event.event.venue?.city
                        }
                    }));

                    subscriptions = shop.shopPages[0].shopSubscriptionTypes.map((event: any) => ({
                        type: 'SUBSCRIPTION_TYPE',
                        id: event.subscriptionType.id,
                        slug: event.subscriptionType.id,
                        name: event.subscriptionType.name,
                        date: event.subscriptionType.startAt,
                        description: event.subscriptionType.description,
                        venue: {
                            id: event.subscriptionType.venue?.id,
                            name: event.subscriptionType.venue?.name,
                            city: event.subscriptionType.venue?.city
                        }
                    }));
                }

                return {
                    id: shop.id,
                    name: shop.name,
                    slug: shop.slug,
                    events: [...events, ...subscriptions],
                    style: {
                        theme: 'DARK',
                        primary: shop.shopStyle.buttonPrimary.backgroundColor,
                        backgroundImage: shop.shopStyle.general.backgroundImage
                    },
                    currencyId: shop.organisation.currencyId,
                    supportUrl: shop.supportUrl
                }
            })
        );
    }

    public postPayment(body: any): Observable<any> {
        return this.http.post<any>(`${environment.API_URL}/seatedapi/v1.0/public/payment`, body, {
            withCredentials: true,
            headers: new HttpHeaders().set('X-TF-SHOPGUID', this.shopId),
        });
    }

    public getPaymentStatus(paymentId: string): Observable<GetPaymentStatusResponse> {
        return this.http.get<GetPaymentStatusResponse>(`${environment.API_URL}/seatedapi/v1.0/public/payment/${paymentId}/status`, {
            withCredentials: true,
            headers: new HttpHeaders().set('X-TF-SHOPGUID', this.shopId),
        });
    }

    public getTicketTypes(eventId: string, eventType: EventType): Observable<TicketType[]> {
        if (eventType === 'EVENT') {
            return this.http.get<TicketType[]>(`${environment.API_URL}/seatedapi/v1.0/public/event/${eventId}/tickettype`, {
                withCredentials: true,
                headers: new HttpHeaders().set('X-TF-SHOPGUID', this.shopId),
                params: new HttpParams().set('depth', 2)
            }).pipe(
                map((ticketTypes: any) => {
                    return ticketTypes.map((ticketType: any) => {
                        return new TicketType(
                            ticketType.id,
                            ticketType.name,
                            ticketType.prices.map((price: any) => {
                                return {
                                    groupId: price.venueSectionGroupId,
                                    price: price.price
                                }
                            })
                        );
                    })
                })
            );
        } else if (eventType === 'SUBSCRIPTION_TYPE') {
            return this.http.get<TicketType[]>(`${environment.API_URL}/seatedapi/v1.0/public/subscription/type/${eventId}/price`, {
                withCredentials: true,
                headers: new HttpHeaders().set('X-TF-SHOPGUID', this.shopId),
                params: new HttpParams().set('depth', 2)
            }).pipe(
                map((ticketTypes: any) => {
                    return ticketTypes.map((ticketType: any) => {
                        return new TicketType(
                            ticketType.id,
                            ticketType.name,
                            ticketType.priceItems.map((price: any) => {
                                return {
                                    groupId: price.venueSectionGroupId,
                                    price: price.price
                                }
                            })
                        );
                    })
                })
            );
        }

        return throwError(() => of('Event type not supported'));
    }

    public postDiscount(orderId: string, code: string): Observable<ResponseBody> {
        return this.http.post<ResponseBody>(`${environment.API_URL}/seatedapi/v1.0/public/order/${orderId}/discount`, {code: code}, {
            withCredentials: true,
            headers: new HttpHeaders().set('X-TF-SHOPGUID', this.shopId)
        });
    }
}

export class TicketType {
    constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly prices: {
            groupId: string;
            price: number
        }[]
    ) {
    }

    public hasPriceInGroup(groupId: string): boolean {
        return this.prices.some((price: any) => price.groupId === groupId);
    }

    public getPriceForGroup(groupId: string): any {
        return this.prices.find((price: any) => price.groupId === groupId);
    }
}

type ResponseBody = any;

type PostOrderResponse = {
    id: string;
}

type PostOrderItemResponse = {
    id: string;
    orderId: string;
}

type GetPaymentMethodResponse = {
    method: string;
    name: string;
    issuers: {
        id: string;
        name: string;
    }[];
}

type GetPaymentStatusResponse = {
    status: string;
}
