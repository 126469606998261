<div class="pan-zoom-container">
    <div class="pan-zoom-content">
        @for (seat of section.seats; track seat.id) {
            <button [style.left.px]="seat.x" [style.top.px]="seat.y"
                    [class]="seat.state?.toLowerCase()"
                    (click)="onSeatClick(seat)"
                    [attr.popovertargetaction]="seat.state === 'AVAILABLE' ? 'show' : 'hide'"
                    popovertarget="seat-detail"></button>
        }
    </div>
</div>

<div class="actions">
    <button (click)="resetPosition()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <path fill="var(--button-text-color)" fill-rule="evenodd"
                  d="M6 8.781V7a1 1 0 1 0-2 0v4a1 1 0 0 0 1 1h4a1 1 0 1 0 0-2H7.616c.937-1.83 2.806-3 4.884-3 3.032 0 5.5 2.468 5.5 5.5S15.532 18 12.5 18a1 1 0 1 0 0 2c4.136 0 7.5-3.364 7.5-7.5S16.636 5 12.5 5A7.48 7.48 0 0 0 6 8.781"/>
        </svg>
    </button>
</div>

<div id="seat-detail" popover role="dialog" (toggle)="onSeatDetailPopoverToggle($event)">
    @if (selectedSeat; as seat) {
        <div class="seat-detail-location">
            <div class="seat-detail-location-section">
                <div class="seat-detail-header">{{ 'Seat_Map.Seat_Location.Section.Title' | translation }}</div>
                {{ section.name }}
            </div>

            <div class="seat-detail-location-section">
                <div class="seat-detail-header">{{ 'Seat_Map.Seat_Location.Row.Title' | translation }}</div>
                {{ seat.row }}
            </div>

            <div class="seat-detail-location-section">
                <div class="seat-detail-header">{{ 'Seat_Map.Seat_Location.Seat.Title' | translation }}</div>
                {{ seat.seat }}
            </div>
        </div>

        <div class="ticket-types">
            @for (ticketType of ticketTypes; track ticketType.id) {
                <div class="ticket-type-option">
                    <div class="name">{{ ticketType.name }}</div>
                    <div class="price">{{ ticketType.getPriceForGroup(section.groupId).price | currency }}</div>
                    <button class="cta" popovertarget="seat-detail"
                            (click)="onTicketTypeSelectForSeat(seat.id, ticketType.id)">
                        {{ 'Seat_Map.Ticket_Type.Select_Button.Label' | translation }}
                    </button>
                </div>
            }
        </div>
    }
</div>
