import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class TranslationService {

    private translations: Map<string, string> = new Map();

    private CM_TRANSLATION_API_URL = 'https://api.cm.com/translations/v1.0/scopes/sports-shop/keys/';

    constructor(@Inject(LOCALE_ID) private localeId: string, private http: HttpClient) {
    }

    public getTranslationsFromApi(): void {
        this.http.get<ExternalTranslation[]>(this.CM_TRANSLATION_API_URL).subscribe((translations: ExternalTranslation[]) => {
            translations.forEach(this.getValueFromExternalTranslation.bind(this))
        });
    }

    public getTranslation(key: string): string | undefined {
        return this.translations.get(key);
    }

    public hasTranslation(key: string): boolean {
        return this.translations.has(key);
    }

    private getValueFromExternalTranslation(translation: ExternalTranslation): void {
        this.translations.set(translation.ID, translation.Phrases.find((phrase: Phrase) => phrase.LanguageID === this.localeId)!.Value);
    }
}

type ExternalTranslation = {
    ID: string;
    Phrases: Phrase[];
}

type Phrase = {
    LanguageID: string;
    Value: string;
}
