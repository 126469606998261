import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnInit,
    ViewChild
} from '@angular/core';
import {AppService} from '../app.service';
import {Router} from '@angular/router';
import {ApiService} from '../api.service';
import {
    catchError, forkJoin,
    map,
    Observable,
    of, startWith,
    Subject,
    switchMap,
    tap
} from 'rxjs';
import {AsyncPipe, CurrencyPipe, DatePipe, JsonPipe, KeyValuePipe} from '@angular/common';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule, ValidationErrors, ValidatorFn,
    Validators
} from '@angular/forms';
import {TranslationPipe} from '../translation.pipe';
import {Order} from '../../types';
import {CountryCode, isValidPhoneNumber} from 'libphonenumber-js';
import {OrderOverviewComponent} from '../order-overview/order-overview.component';
import {HeaderComponent} from '../header/header.component';

@Component({
    selector: 'app-checkout',
    standalone: true,
    imports: [
        AsyncPipe,
        DatePipe,
        CurrencyPipe,
        FormsModule,
        ReactiveFormsModule,
        TranslationPipe,
        KeyValuePipe,
        OrderOverviewComponent,
        JsonPipe,
        HeaderComponent,
    ],
    templateUrl: './checkout.component.html',
    styleUrl: './checkout.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutComponent implements OnInit {

    public checkoutForm!: FormGroup;

    public viewModel$!: Observable<{ order: Order, paymentMethods: any[] }>;

    public isPaidOrder = true;

    public paymentMethodsWithIssuers: string[] = [];

    get consentsFormGroup(): FormGroup {
        return this.checkoutForm.get('consents') as FormGroup;
    }

    private orderId: string | null = null;

    private getOrder$: Subject<string> = new Subject<string>();

    public discountState: 'APPLIED' | 'ERROR' | 'NONE' = 'NONE';

    constructor(private readonly app: AppService, private readonly api: ApiService, private readonly router: Router, private formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.orderId = window.localStorage.getItem('orderId');

        if (!this.orderId) {
            void this.router.navigate([this.app.shop.slug]);
            return;
        }

        this.checkoutForm = this.formBuilder.group({
            customerData: this.formBuilder.group({
                firstName: [null, Validators.required],
                lastName: [null, Validators.required],
                email: [null, [Validators.required, CheckoutComponent.emailValidator()]],
                phone: [null, {
                    validators: [Validators.required, CheckoutComponent.phoneNumberValidator()],
                    updateOn: 'blur'
                }]
            }),
            consents: this.formBuilder.group({}),
            payment: this.formBuilder.group({
                method: ['', Validators.required],
                issuer: ['', Validators.required],
            })
        });

        const order$: Observable<Order> = this.api.getOrder(this.orderId).pipe(
            catchError(() => {
                void this.router.navigate([this.app.shop.slug]);
                return of();
            }),
            tap((order: Order) => {
                this.isPaidOrder = order.price > 0;

                if (!this.isPaidOrder) {
                    this.checkoutForm.get('payment')?.get('method')?.setValue('FREE');
                    this.checkoutForm.get('payment')?.get('issuer')?.setValue(null);
                }

                order.consents.forEach((consent: any) => {
                    const validators = [];
                    if (consent.isRequired) {
                        validators.push(Validators.requiredTrue)
                    }

                    this.consentsFormGroup.registerControl(consent.id, new FormControl(false, validators));
                });
            })
        );

        const paymentMethods$ = this.api.getPaymentMethods(this.orderId).pipe(
            tap((paymentMethods: any[]) => {
                this.paymentMethodsWithIssuers = paymentMethods
                    .filter((paymentMethod: any) => paymentMethod.issuers.length > 0)
                    .map((paymentMethod: any) => paymentMethod.method);
            })
        );

        this.viewModel$ = this.getOrder$.pipe(
            startWith(''),
            switchMap(() => {
                return forkJoin([order$, paymentMethods$]).pipe(
                    map((results: [Order, any]) => {
                        return {
                            order: results[0],
                            paymentMethods: results[1]
                        }
                    })
                );
            }),
            tap((viewModel: { order: Order, paymentMethods: any[] }) => {
                if (viewModel.order.discounts.size > 0) {
                    this.discountState = 'APPLIED';
                }
            })
        );

        this.checkoutForm.get('payment')?.get('method')?.valueChanges.subscribe((paymentMethodId: string) => {
            const issuerControl = this.checkoutForm.get('payment')?.get('issuer');
            if (!issuerControl) {
                return;
            }

            if (this.paymentMethodsWithIssuers.includes(paymentMethodId)) {
                issuerControl.addValidators(Validators.required);
            } else {
                issuerControl.removeValidators(Validators.required);
            }

            issuerControl.setValue(null);
            issuerControl.markAsUntouched();
        });
    }

    public onCustomerDataFormSubmit(): void {
        if (this.checkoutForm.invalid) {
            this.checkoutForm.markAllAsTouched();
            return;
        }

        this.app.finishOrder(this.checkoutForm.value);
    }

    static emailValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: ValidationErrors } | null => {
            const emailValidatorRegex = RegExp('^[a-zA-Z0-9.!#$%&\'*+\/=?^_`{|}~-]+@[a-z0-9.-]+\\.[a-z0-9.-]{2,12}$');
            return emailValidatorRegex.test(control.value) ? null : {email: {value: control.value}};
        };
    }

    static phoneNumberValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: ValidationErrors } | null => {
            if (!control.value) {
                return null;
            }

            const supportedCountryCodes: CountryCode[] = ['NL', 'BE', 'GB'];
            const valid = supportedCountryCodes.some((countryCode: CountryCode) => {
                return isValidPhoneNumber(control.value, countryCode)
            });

            return valid ? null : {phone: {value: control.value}};
        }
    }

    public hasConsentErrors(): boolean {
        const consentFormGroup = this.checkoutForm.get('consents') as FormGroup;
        return Object.keys(consentFormGroup.controls).some(key => consentFormGroup.get(key)?.touched && consentFormGroup.get(key)?.errors)
    }

    public onAddUpsellItemClick(upsellOptionId: string, upsellOptionTypeId: string): void {
        if (!this.orderId) {
            return;
        }

        this.api.postOrderItems(this.orderId, {
            product: {
                productTypeId: upsellOptionId,
                productTypePriceId: upsellOptionTypeId
            }
        }).subscribe({
            next: () => {
                this.getOrder$.next('');
            },
        });
    }

    public onBackClick(): void {
        if (this.app.event) {
            void this.router.navigate([this.app.shop.slug, 'event', this.app.event.id]);
            return;
        }

        void this.router.navigate([this.app.shop.slug]);
    }

    public applyDiscountToOrder(code: string): void {
        this.app.applyDiscountToOrder(code).subscribe({
            next: () => {
                this.discountState = 'APPLIED';
                this.getOrder$.next('');
            },
            error: () => {
                this.discountState = 'ERROR';
            }
        });
    }
}
