import {Component, Input, OnInit} from '@angular/core';
import {AsyncPipe, CurrencyPipe, KeyValue, KeyValuePipe} from '@angular/common';
import {TranslationPipe} from '../translation.pipe';
import {Fee, Order} from '../../types';
import {interval, map, Observable, startWith, takeWhile} from 'rxjs';

@Component({
    selector: 'app-order-overview',
    standalone: true,
    imports: [
        AsyncPipe,
        CurrencyPipe,
        KeyValuePipe,
        TranslationPipe
    ],
    templateUrl: './order-overview.component.html',
    styleUrl: './order-overview.component.css'
})
export class OrderOverviewComponent implements OnInit {
    @Input()
    order!: Order;

    public countDown$!: Observable<{ minutes: string, seconds: string }>;

    ngOnInit(): void {
        this.countDown$ = interval(1000).pipe(
            startWith({
                minutes: '--',
                seconds: '--'
            }),
            map(() => {
                return this.calculateTimeLeft(new Date(this.order.expireAt))
            }),
            takeWhile(timeLeft => timeLeft.minutes > 0 || timeLeft.seconds > 0),
            map((timeLeft: TimeLeft) => {
                return {
                    minutes: timeLeft.minutes.toString().padStart(2, '0'),
                    seconds: timeLeft.seconds.toString().padStart(2, '0'),
                }
            })
        );
    }

    private calculateTimeLeft(targetDate: Date): TimeLeft {
        const now = new Date();
        const difference = targetDate.getTime() - now.getTime();

        if (difference <= 0) {
            return {minutes: 0, seconds: 0};
        }

        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        return {minutes, seconds};
    }

    /** keyvalue pipe returns values sorted alphabetically by key. This solves this */
    public originalOrderFn = (a: KeyValue<string, Fee>, b: KeyValue<string, Fee>): number => {
        return 0;
    }
}

type TimeLeft = {
    minutes: number;
    seconds: number;
}
