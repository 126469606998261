<div>
    <ng-content></ng-content>
</div>

<div class="support">
    <a href="https://www.cm.com/ticketing-platform" id="support-link" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <path fill="var(--body-color)" fill-rule="evenodd"
                  d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16m0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10m1.265-9.588c-.245.18-.32.33-.32.701 0 .49-.417.887-.93.887s-.93-.397-.93-.887c0-1.18.434-1.731 1.442-2.37.54-.344.613-.438.613-.896 0-.703-.467-1.07-1.12-1.074-.641 0-1.152.359-1.16 1.083-.006.486-.42.877-.93.877-.523-.005-.936-.406-.93-.896C9.02 8.128 10.357 7 12.011 7 13.675 7.008 15 8.143 15 9.847c0 1.18-.434 1.73-1.442 2.37a5 5 0 0 0-.293.195M12 17a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
        </svg>
    </a>
</div>
