import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../api.service';
import {mergeMap, Observable, of, retry, throwError} from 'rxjs';
import {AsyncPipe} from '@angular/common';
import {AppService} from '../app.service';
import {TranslationPipe} from '../translation.pipe';

@Component({
    selector: 'app-payment-status',
    standalone: true,
    imports: [
        AsyncPipe,
        TranslationPipe
    ],
    templateUrl: './payment-status.component.html',
    styleUrl: './payment-status.component.css'
})
export class PaymentStatusComponent implements OnInit {

    public status!: string;
    public paymentStatus$!: Observable<any>;

    constructor(private readonly route: ActivatedRoute, private readonly api: ApiService, private readonly router: Router, private readonly app: AppService) {
    }

    ngOnInit(): void {
        this.status = (this.route.snapshot.params['status'] as string).toUpperCase();
        const paymentId = this.route.snapshot.params['paymentId'];

        this.paymentStatus$ = this.api.getPaymentStatus(paymentId).pipe(
            mergeMap(paymentStatus => paymentStatus.status === 'OPEN' ? throwError(() => '') : of(paymentStatus)),
            retry({count: 10, delay: 3000})
        );
    }

    public onTryAgainClick(): void {
        void this.router.navigate([this.app.shop.slug, 'checkout'])
    }
}
