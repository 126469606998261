<app-header>
    <button (click)="onBackClick()">{{ 'Event.Back_Button.Label' | translation }}</button>
</app-header>

@if (viewModel$ | async; as viewModel) {
    <header>
        <h1>{{ 'Checkout.Title' | translation }}</h1>
    </header>

    <main>
        <form [formGroup]="checkoutForm" (ngSubmit)="onCustomerDataFormSubmit()">
            <h2>{{ 'Checkout.Customer_Data_Section.Title' | translation }}</h2>
            <div formGroupName="customerData">
                <div class="row">
                    <label for="first-name">
                        <span>{{ 'Checkout.Customer_Data.First_Name.Label' | translation }}</span>
                        <input id="first-name" type="text" formControlName="firstName" required
                               autocomplete="given-name">
                    </label>

                    <label for="last-name">
                        <span>{{ 'Checkout.Customer_Data.Last_Name.Label' | translation }}</span>
                        <input id="last-name" type="text" formControlName="lastName" required
                               autocomplete="family-name">
                    </label>
                </div>

                <div class="row">
                    <label for="email">
                        <span>{{ 'Checkout.Customer_Data.Email.Label' | translation }}</span>
                        <input id="email" type="text" inputmode="email" formControlName="email" required
                               autocomplete="email">
                    </label>
                </div>

                <div class="row">
                    <label for="phone">
                        <span>{{ 'Checkout.Customer_Data.Phone_Number.Label' | translation }}</span>
                        <input id="phone" type="text" inputmode="tel" formControlName="phone" required
                               autocomplete="tel-national">
                    </label>
                </div>
            </div>

            @if (viewModel.order.upsellOptions.length > 0) {
                <div class="extra-items">
                    <h2>{{ ('Checkout.Extra_Items.Title' | translation) }}</h2>
                    @for (upsellOption of viewModel.order.upsellOptions; track upsellOption.id) {
                        <h3>{{ upsellOption.name }}</h3>
                        <div class="extra-item-actions">
                            @if (upsellOption.upsellOptionTypes.length > 1) {
                                <select #upsellOptionTypeSelect>
                                    @for (upsellOptionType of upsellOption.upsellOptionTypes; track upsellOptionType.id) {
                                        <option [value]="upsellOptionType.id">
                                            {{ upsellOptionType.name }} ({{ upsellOptionType.price | currency }})
                                        </option>
                                    }
                                </select>
                                <button type="button"
                                        (click)="onAddUpsellItemClick(upsellOption.id, upsellOptionTypeSelect.value)">
                                    {{ 'General.Add' | translation }}
                                </button>
                            } @else {
                                <div>{{ upsellOption.upsellOptionTypes[0].name }}
                                    ({{ upsellOption.upsellOptionTypes[0].price | currency }})
                                </div>
                                <button type="button"
                                        (click)="onAddUpsellItemClick(upsellOption.id, upsellOption.upsellOptionTypes[0].id)">
                                    {{ 'General.Add' | translation }}
                                </button>
                            }
                        </div>
                    }
                </div>
            }

            @if (isPaidOrder) {
                <h2>{{ 'Checkout.Payment_Method_Section.Title' | translation }}</h2>
                <div formGroupName="payment">
                    @for (paymentMethod of (viewModel.paymentMethods); track paymentMethod.method) {
                        <label [attr.for]="'payment-method-' + paymentMethod.method" class="payment-method">
                            <input [attr.id]="'payment-method-' + paymentMethod.method" type="radio"
                                   formControlName="method" [value]="paymentMethod.method">
                            {{ paymentMethod.name }}
                        </label>

                        @if (paymentMethodsWithIssuers.includes(checkoutForm.get('payment')?.value['method']) && paymentMethodsWithIssuers.includes(paymentMethod.method)) {
                            <select class="payment-method-issuer" formControlName="issuer">
                                <option disabled [value]="null">Select your bank</option>
                                @for (issuer of paymentMethod.issuers; track issuer.id) {
                                    <option [value]="issuer.id">{{ issuer.name }}</option>
                                }
                            </select>
                        }
                    }
                </div>

                <details>
                    <summary>{{ 'Checkout.Discount.Toggle_Button.Label' | translation }}</summary>
                    @if (discountState === 'NONE' || discountState === 'ERROR') {
                        <label for="discount" class="with-button">
                            <span class="label">{{ 'Checkout.Discount.Code.Label' | translation }}</span>
                            <input id="discount" type="text" #discountControl>
                            <button type="button"
                                    (click)="applyDiscountToOrder(discountControl.value)">{{ 'Checkout.Discount.Validate_Button.Label' | translation }}
                            </button>
                        </label>

                        @if (discountState === 'ERROR') {
                            <div class="validation-error">{{ 'Checkout.Discount.Validation_Error' | translation }}</div>
                        }
                    }

                    @if (discountState === 'APPLIED') {
                        <div class="discounts">
                            @for (discount of viewModel.order.discounts | keyvalue; track discount.key) {
                                <div>{{ discount.value.label }}: {{ discount.value.amount | currency }}</div>
                            }
                        </div>
                    }
                </details>

            }

            <div formGroupName="consents">
                <h2>{{ 'Checkout.Consent_Section.Title' | translation }}</h2>
                @for (consent of viewModel.order.consents; track consent.id; let i = $index) {
                    <label [attr.for]="'consent-'+consent.id" class="consent">
                        <input [attr.id]="'consent-'+consent.id" type="checkbox" [formControlName]="consent.id"
                               [value]="consent.id" [attr.required]="consent.isRequired ? true : undefined">
                        <div class="consent-label">
                            <span class="label" [innerHTML]="consent.name"></span>
                            @if (consent.resource) {
                                <a [href]="consent.resource" class="consent-resource" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                        <path fill="var(--body-color)" fill-rule="evenodd"
                                              d="M8 3a1 1 0 0 1 .117 1.993L8 5H7a2 2 0 0 0-1.995 1.85L5 7v10a2 2 0 0 0 1.85 1.995L7 19h10a2 2 0 0 0 1.995-1.85L19 17v-1l.007-.117a1 1 0 0 1 1.986 0L21 16v1a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V7a4 4 0 0 1 4-4zm12 0 .081.003.068.008a1 1 0 0 1 .117.025l.046.014a1 1 0 0 1 .18.078l.028.017a1 1 0 0 1 .075.051l.022.017.09.08a1 1 0 0 1 .148.187l.016.03a1 1 0 0 1 .093.224 1 1 0 0 1 .035.234L21 4v6a1 1 0 1 1-2 0V6.414l-6.293 6.293a1 1 0 0 1-1.497-1.32l.083-.094L17.584 5H14a1 1 0 1 1 0-2z"/>
                                    </svg>
                                </a>
                            }
                        </div>
                    </label>
                }

                @if (hasConsentErrors()) {
                    <div class="validation-error">{{ 'Checkout.Consent_Validation.Not_All_Required_Accepted.Label' | translation }}
                    </div>
                }
            </div>

            <div class="row">
                <button class="full">
                    {{ isPaidOrder ? ('Checkout.Pay_Button.Label' | translation) : ('Checkout.Finish_Button.Label' | translation) }}
                </button>
            </div>
        </form>
    </main>

    <aside>
        <div class="border">
            <app-order-overview [order]="viewModel.order"></app-order-overview>
        </div>
    </aside>

    <div class="order-overview-button">
        <button popovertarget="order-overview-popover" class="full">
            {{ 'Checkout.View_Order_Button.Label' | translation }} - {{ viewModel.order.price | currency }}
        </button>

        <div popover id="order-overview-popover" class="mobile-order-overview">
            <app-order-overview [order]="viewModel.order"></app-order-overview>
            <button popovertarget="order-overview-popover">Close</button>
        </div>
    </div>
}
