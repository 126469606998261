<router-outlet/>

<dialog #dialog (close)="onDialogClose()">
    @if (dialog.hasAttribute('data-title')) {
        <div class="title">{{ dialog.getAttribute('data-title')! | translation }}</div>
    }

    @if (dialog.hasAttribute('data-body')) {
        <div class="body">{{ dialog.getAttribute('data-body')! | translation }}</div>
    }

    <div class="actions">
        <div class="error-code">
            @if (dialog.hasAttribute('data-error-code')) {
                {{ dialog.getAttribute('data-error-code') }}
            }
        </div>
        <div class="text-right">
            <button (click)="dialog.close()">Close</button>
        </div>
    </div>
</dialog>
