import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {CommonModule} from '@angular/common';
import {Router} from '@angular/router';
import {TranslationPipe} from '../translation.pipe';
import {Shop} from '../../types';
import {HeaderComponent} from '../header/header.component';

@Component({
    selector: 'app-index',
    standalone: true,
    imports: [
        CommonModule,
        TranslationPipe,
        HeaderComponent,
    ],
    templateUrl: './index.component.html',
    styleUrl: './index.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexComponent implements OnInit {

    public state: 'LOADING' | 'LOADED' | 'EMPTY' = 'LOADING';

    public shop!: Shop;

    constructor(private readonly app: AppService, private readonly router: Router) {
    }

    ngOnInit(): void {
        this.shop = this.app.shop;

        if (this.shop.events.length === 0) {
            this.state = 'EMPTY';
        } else {
            this.state = 'LOADED';
        }
    }

    public navigateToEvent(eventId: string): void {
        void this.router.navigate([this.shop.slug, 'event', eventId])
    }
}
