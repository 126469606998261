<app-header>
    <button (click)="onBackClick()">{{ 'Event.Back_Button.Label' | translation }}</button>
</app-header>

<header>
    <h1>{{ event.name }}</h1>

<!--    @if (event.description) {-->
<!--        <button popovertarget="event-information">More information</button>-->
<!--    }-->
</header>

<main>
    @switch (state) {
        @case ('LOADING') {
            {{ 'Event.Loading_State.Body' | translation }}
        }

        @case ('ERROR') {
            {{ 'Event.Error_State.Body' | translation }}
        }
        @case ('EMPTY') {
            {{ 'Event.Empty_State.Body' | translation }}
        }

        @case ('FINISHED') {
            @if (!section) {
                <app-section-selection [venue]="venue" (select)="onSectionSelect($event)"></app-section-selection>
            } @else {
                <button class="secondary section-back-button" (click)="clearSection()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                        <path fill="var(--button-option-text-color)" fill-rule="evenodd"
                              d="M5.305 12.72a.997.997 0 0 1 .01-1.45l4.978-4.977a.999.999 0 1 1 1.414 1.414L8.414 11H18a1 1 0 0 1 0 2H8.414l3.293 3.293a.999.999 0 1 1-1.414 1.414z"/>
                    </svg>
                    {{ section.name }}
                </button>
                <app-ticket-selection
                    [section]="section"
                    [ticketTypes]="ticketTypesForSection"
                    (select)="onTicketsSelect($event)"
                ></app-ticket-selection>
            }
        }
    }
</main>

<div id="event-information" class="sidebar" popover>
    <h1>{{ event.name }}</h1>

    @if (event.date) {
        <div>{{ event.date | date }}</div>
    }

    @if (event.venue) {
        <div>{{ event.venue.name }}</div>
    }

    <div class="sidebar-content" [innerHTML]="event.description"></div>
    <button popovertarget="event-information" class="sidebar-close">{{ 'Event.Event_Details_Sidebar.Close_Button.Label' | translation }}</button>
</div>
