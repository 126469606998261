<div class="order-overview-line">
    <h3>Your order</h3>
    <div class="timer">
        @if (countDown$ | async; as timeLeft) {
            <h3>{{ timeLeft.minutes }}:{{ timeLeft.seconds }}</h3>
        }
    </div>
</div>
@for (item of order.orderItems; track item.id) {
    <div class="order-overview-line">
        <span>{{ item.name }}</span>
        <span>{{ item.price | currency }}</span>
    </div>
}

@if (order.fees.size > 0) {
    <section>
        @for (fee of order.fees | keyvalue:originalOrderFn; track fee.key) {
            @if (fee.value.amount > 0) {
                <div class="order-overview-line">
                    <span>{{ fee.value.label }}</span>
                    <span>{{ fee.value.amount | currency }}</span>
                </div>
            }
        }
    </section>
}

@if (order.discounts.size > 0) {
    <section>
        @for (discount of order.discounts | keyvalue:originalOrderFn; track discount.key) {
            <div class="order-overview-line discount">
                <span>{{ discount.value.label }}</span>
                <span>{{ discount.value.amount | currency }}</span>
            </div>
        }
    </section>
}

<div class="order-overview-line price">
    <span>{{ 'Checkout.Order_Overview.Total' | translation }}</span>
    <span>
        @if (order.price > 0) {
            {{ order.price | currency }}
        } @else {
            {{ 'Checkout.Order_Overview.Total.Free' | translation }}
        }
    </span>
</div>
