import {Pipe, PipeTransform} from '@angular/core';
import {TranslationService} from './translation/translation.service';

@Pipe({
    name: 'translation',
    standalone: true,
    pure: true
})
export class TranslationPipe implements PipeTransform {

    constructor(private readonly translationService: TranslationService) {
    }

    transform(value: string): string {
        return this.translationService.getTranslation(value) ?? `{{${value}}}`;
    }

}
