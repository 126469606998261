import {ActivatedRouteSnapshot, CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';

export const canEnterCheckoutGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
    const orderId = window.localStorage.getItem('orderId');

    if (!orderId) {
        void inject(Router).navigate([route.params['shopSlug']]);
        return false;
    }

    return true;
};
