import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {Seat, Section} from '../../types';
import {AppService, Reservation} from '../app.service';
import {ApiService, TicketType} from '../api.service';
import {CurrencyPipe, JsonPipe, KeyValuePipe} from '@angular/common';
import {TranslationPipe} from '../translation.pipe';

@Component({
    selector: 'app-seat-map',
    standalone: true,
    imports: [
        JsonPipe,
        KeyValuePipe,
        CurrencyPipe,
        TranslationPipe
    ],
    templateUrl: './seat-map.component.html',
    styleUrl: './seat-map.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class]': 'this.state'
    }
})
export class SeatMapComponent implements OnInit, OnDestroy {
    @Input()
    section!: Section;

    @Input()
    ticketTypes: TicketType[] = [];

    @Output()
    seatClick: EventEmitter<string> = new EventEmitter<string>();

    private initialAvailableSeatIds: string[] = [];

    public state: 'PANNING' | 'DEFAULT' = 'DEFAULT';

    _scale = 1;
    translateX = 0;
    translateY = 0;
    lastX = 0;
    lastY = 0;

    private initialScale!: number;
    private initialTranslateX!: number;
    private initialTranslateY!: number;
    private initialPinchDistance: number | null = null;
    private initialScaleForPinch: number | null = null;

    public selectedSeat: Seat | undefined;

    @HostListener('wheel', ['$event'])
    onWheel(event: WheelEvent) {
        event.preventDefault();
        if (event.deltaY < 0) {
            this.zoomIn(event);
        } else {
            this.zoomOut(event);
        }
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        this.state = 'PANNING';
        this.lastX = event.clientX;
        this.lastY = event.clientY;
    }

    @HostListener('mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        if (this.state === 'DEFAULT') return;
        this.translateX += event.clientX - this.lastX;
        this.translateY += event.clientY - this.lastY;
        this.lastX = event.clientX;
        this.lastY = event.clientY;
        this.updateTransform();
    }

    @HostListener('mouseup')
    @HostListener('mouseleave')
    onMouseUp() {
        this.state = 'DEFAULT';
    }

    @HostListener('touchstart', ['$event'])
    onTouchStart(event: TouchEvent) {
        if (event.touches.length === 1) {
            this.state = 'PANNING';
            this.lastX = event.touches[0].clientX;
            this.lastY = event.touches[0].clientY;
        } else if (event.touches.length === 2) {
            this.initialPinchDistance = this.getPinchDistance(event);
            this.initialScaleForPinch = this.scale;
        }
    }

    @HostListener('touchmove', ['$event'])
    onTouchMove(event: TouchEvent) {
        if (this.state === 'PANNING' && event.touches.length === 1) {
            this.translateX += event.touches[0].clientX - this.lastX;
            this.translateY += event.touches[0].clientY - this.lastY;
            this.lastX = event.touches[0].clientX;
            this.lastY = event.touches[0].clientY;
            this.updateTransform();
        } else if (event.touches.length === 2) {
            this.handlePinch(event);
        }
    }

    @HostListener('touchend')
    @HostListener('touchcancel')
    onTouchEnd() {
        this.state = 'DEFAULT';
        this.initialPinchDistance = null;
        this.initialScaleForPinch = null;
    }

    private handlePinch(event: TouchEvent) {
        if (this.initialPinchDistance === null || this.initialScaleForPinch === null) return;

        const pinchDistance = this.getPinchDistance(event);
        const scaleChange = pinchDistance / this.initialPinchDistance;

        this.scale = this.initialScaleForPinch * scaleChange;
        this.updateTransform();
    }

    private getPinchDistance(event: TouchEvent): number {
        const touch1 = event.touches[0];
        const touch2 = event.touches[1];

        const dx = touch2.clientX - touch1.clientX;
        const dy = touch2.clientY - touch1.clientY;

        return Math.sqrt(dx * dx + dy * dy);
    }

    updateTransform() {
        const content = document.querySelector('.pan-zoom-content') as HTMLElement;
        content.style.transform = `translate(${this.translateX}px, ${this.translateY}px) scale(${this.scale})`;
    }

    constructor(private readonly app: AppService, private readonly api: ApiService, private readonly changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        if (!this.section) {
            throw new Error('No section found for this section');
        }

        this.api.getSectionAvailability(this.app.venueId, this.section.id, this.app.event.id, this.app.event.type).subscribe((seatIds: string[]) => {
            this.section.seats.forEach(seat => {
                seat.state = seatIds.includes(seat.id) ? 'AVAILABLE' : 'UNAVAILABLE';
            });

            this.initialAvailableSeatIds = seatIds;

            this.fitButtonsInView();

            this.changeDetectorRef.detectChanges();
        })

        this.app.getReservation$().subscribe({
            next: (reservation: Reservation) => {
                this.section.seats.forEach((seat: Seat) => {
                    const seatInReservation = reservation.items.findIndex((item: any) => item.seatId === seat.id) > -1;

                    if (seatInReservation) {
                        seat.state = 'CURRENT_ORDER';
                    } else {
                        seat.state = this.initialAvailableSeatIds.includes(seat.id) ? 'AVAILABLE' : 'UNAVAILABLE';
                    }
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.app.clearReservation();
    }

    public onSeatClick(seat: Seat): void {
        this.selectedSeat = undefined;

        switch (seat.state) {
            case 'AVAILABLE':
                this.selectedSeat = seat;
                break;
            case 'CURRENT_ORDER':
                this.app.removeReservationSeat({
                    eventId: this.app.event.id,
                    seatId: seat.id
                });
                break;
            case 'UNAVAILABLE':
                return;
        }
    }

    private fitButtonsInView(): void {
        const container = document.querySelector('.pan-zoom-container') as HTMLElement;
        const containerRect = container.getBoundingClientRect();

        const minX = Math.min(...this.section.seats.map(seat => seat.x));
        const minY = Math.min(...this.section.seats.map(seat => seat.y));
        const maxX = Math.max(...this.section.seats.map(seat => seat.x));
        const maxY = Math.max(...this.section.seats.map(seat => seat.y));

        const buttonsWidth = maxX - minX;
        const buttonsHeight = maxY - minY;

        const paddingX = 100;
        const paddingY = 100;

        const scaleX = containerRect.width / (buttonsWidth + paddingX);
        const scaleY = containerRect.height / (buttonsHeight + paddingY);
        this.initialScale = this.scale = Math.min(scaleX, scaleY);

        this.initialTranslateX = this.translateX = (containerRect.width - buttonsWidth * this.scale) / 2 - minX * this.scale;
        this.initialTranslateY = this.translateY = (containerRect.height - buttonsHeight * this.scale) / 2 - minY * this.scale;

        this.initialScale = this.scale;
        this.initialTranslateX = this.translateX;
        this.initialTranslateY = this.translateY;

        this.updateTransform();
    }

    public resetPosition(): void {
        this.scale = this.initialScale;
        this.translateX = this.initialTranslateX;
        this.translateY = this.initialTranslateY;

        this.updateTransform();
    }

    public zoomIn(event: WheelEvent | MouseEvent): void {
        const container = document.querySelector('.pan-zoom-container') as HTMLElement;
        const containerRect = container.getBoundingClientRect();

        const offsetX = event.clientX - containerRect.left;
        const offsetY = event.clientY - containerRect.top;

        const newScale = this.scale * 1.05;

        this.translateX -= offsetX * (newScale - this.scale) / this.scale;
        this.translateY -= offsetY * (newScale - this.scale) / this.scale;

        this.scale = newScale;

        this.updateTransform();
    }

    public zoomOut(event: WheelEvent | MouseEvent): void {
        const container = document.querySelector('.pan-zoom-container') as HTMLElement;
        const containerRect = container.getBoundingClientRect();

        const offsetX = event.clientX - containerRect.left;
        const offsetY = event.clientY - containerRect.top;

        const newScale = this.scale / 1.05;

        this.translateX -= offsetX * (newScale - this.scale) / this.scale;
        this.translateY -= offsetY * (newScale - this.scale) / this.scale;

        this.scale = newScale;

        this.updateTransform();
    }

    set scale(scale: number) {
        if (scale > 3) {
            this._scale = 3;
            return;
        }

        this._scale = scale;
    }

    get scale(): number {
        return this._scale;
    }

    onTicketTypeSelectForSeat(seatId: string, ticketTypeId: string): void {
        this.app.addToReservation({
            eventId: this.app.event.id,
            seatId: seatId,
            ticketTypeId: ticketTypeId
        });
    }

    onSeatDetailPopoverToggle(e: Event) {
        if ((<ToggleEvent>e).newState === 'closed') {
            this.selectedSeat = undefined;
        }
    }
}
