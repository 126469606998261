import { Component } from '@angular/core';
import {TranslationPipe} from '../translation.pipe';

@Component({
  selector: 'app-status-not-found',
  standalone: true,
    imports: [
        TranslationPipe
    ],
  templateUrl: './status-not-found.component.html',
  styleUrl: './status-not-found.component.css'
})
export class StatusNotFoundComponent {

}
