import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {CommonModule} from '@angular/common';
import {TranslationPipe} from '../translation.pipe';
import {Event, Ring, Section, TicketSelection, Venue} from '../../types';
import {SectionSelectionComponent} from '../section-selection/section-selection.component';
import {TicketSelectionComponent} from '../ticket-selection/ticket-selection.component';
import {TicketType} from '../api.service';
import {forkJoin} from 'rxjs';
import {HeaderComponent} from '../header/header.component';

@Component({
    selector: 'app-event',
    standalone: true,
    imports: [
        RouterLink,
        CommonModule,
        TranslationPipe,
        SectionSelectionComponent,
        TicketSelectionComponent,
        HeaderComponent
    ],
    templateUrl: './event.component.html',
    styleUrl: './event.component.css'
})
export class EventComponent implements OnInit, OnDestroy {

    public event!: Event;

    public section: Section | undefined;

    public venue!: Venue;
    public ticketTypes!: TicketType[];

    public state: 'LOADING' | 'ERROR' | 'FINISHED' | 'EMPTY' = 'LOADING';

    constructor(private readonly app: AppService,
                private readonly route: ActivatedRoute,
                private readonly router: Router) {
    }

    ngOnInit(): void {
        const slug = this.route.snapshot.params['slug'];
        this.event = this.app.getEvent(slug);

        this.app.setEvent(this.event);

        forkJoin([this.app.getVenue(), this.app.getTicketTypes()]).subscribe({
            next: (response: [venue: Venue, ticketTypes: TicketType[]]) => {
                this.venue = response[0];
                this.ticketTypes = response[1];

                this.venue.rings = this.venue.rings.map((ring: Ring) => {
                    const sections = ring.sections.filter((section: Section) => {
                        return this.ticketTypes.some((ticketType: TicketType) => ticketType.hasPriceInGroup(section.groupId));
                    });

                    return {...ring, sections}
                }).filter((ring: Ring) => ring.sections.length > 0);
            },
            error: () => {
                this.state = 'ERROR';
            },
            complete: () => {
                if (this.venue.rings.length === 0) {
                    this.state = 'EMPTY';
                    return;
                }

                this.state = 'FINISHED';
            }
        });
    }

    ngOnDestroy(): void {
        this.app.clearReservation();
    }

    public onBackClick(): void {
        void this.router.navigate([this.app.shop.slug]);
    }

    public onSectionSelect(section: Section): void {
        this.section = section;
    }

    public clearSection(): void {
        this.section = undefined;
    }

    get ticketTypesForSection(): TicketType[] {
        if (!this.section) {
            return [];
        }

        return this.ticketTypes.filter(ticketType => ticketType.hasPriceInGroup(this.section!.groupId))
    }

    onTicketsSelect(tickets: TicketSelection) {
        if (!this.section) {
            return;
        }

        for (let ticketTypeId of tickets.keys()) {
            for (let i = 0; i < tickets.get(ticketTypeId)!; i++) {
                const ticketType = this.getTicketTypeById(ticketTypeId);

                if (!ticketType) {
                    continue;
                }

                this.app.addToReservation({
                    eventId: this.app.event.id,
                    venueSectionId: this.section.id,
                    ticketTypeId: ticketType.id
                });
            }
        }

        this.app.finishReservation();
    }

    private getTicketTypeById(ticketTypeId: string): TicketType | undefined {
        return this.ticketTypes.find(ticketType => ticketType.id === ticketTypeId);
    }
}
