import {HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {inject, LOCALE_ID} from '@angular/core';

export function requestInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    const userLanguage = inject(LOCALE_ID);

    const reqWithParams = req.clone({
        params: req.params.set('language', userLanguage),
    });

    return next(reqWithParams);
}
