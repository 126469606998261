<app-header></app-header>

<header>
    <h1>{{ shop.name }}</h1>
</header>

<main>
    @switch (state) {
        @case ('LOADING') {
            <div class="state-message">
                {{ 'Index.Loading_State.Body' | translation }}
            </div>
        }
        @case ('LOADED') {
            @for (event of shop.events; track event.id) {
                <div class="event">
                    <div class="date">
                        @if (event.date) {
                            <div class="month">{{ event.date | date:'MMM':undefined:'EN' }}</div>
                            <div class="day">{{ event.date | date:'dd' }}</div>
                        }
                    </div>
                    <div class="name">
                        <h3>{{ event.name }}</h3>
                    </div>
                    <div class="cta">
                        @if (event.status === 'UNAVAILABLE') {
                            <button disabled>{{ 'Index.Unavailable_Button.Label' | translation }}
                            </button>
                        } @else {
                            <button (click)="navigateToEvent(event.id)">{{ 'Index.Buy_Button.Label' | translation }}
                            </button>
                        }
                    </div>
                </div>
            }
        }
        @case ('EMPTY') {
            <div class="state-message">
                {{ 'Index.Empty_State.Body' | translation }}
            </div>
        }
    }
</main>
