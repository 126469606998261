@for (ring of venue.rings; track ring.id) {
    <h3>{{ ring.name }}</h3>

    @for (section of ring.sections; track section.id) {
        <button class="full option"
                [disabled]="section.state === 'SOLD_OUT' ? true : undefined"
                (click)="onSectionSelect(section)">{{ section.name }}
            @if (section.state === 'SOLD_OUT') {
                <div class="sold-out-label">{{ 'Section_Selection.Sold_Out.Label' | translation }}</div>
            }
        </button>
    }
}
