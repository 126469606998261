@switch (selectionMode) {
    @case ('NONE') {
        <button class="ticket-selection-mode-option" popovertarget="best-seat-available-popover">
            {{ 'Ticket_Selection.Selection_Mode.Best_Seat_Available_Button.Label' | translation }}
        </button>

        <button class="ticket-selection-mode-option secondary" (click)="setSelectionMode('SEAT_MAP')">
            {{ 'Ticket_Selection.Selection_Mode.Seat_Map_Button.Label' | translation }}
        </button>
    }
    @case ('BEST_SEAT_AVAILABLE') {
        <ng-container *ngTemplateOutlet="bestSeatAvailableTicketSelection"></ng-container>
    }
    @case ('SEAT_MAP') {
        <app-seat-map [section]="section" [ticketTypes]="ticketTypes"></app-seat-map>
        <button class="submit"
                (click)="onSubmit()">{{ 'Ticket_Selection.Seat_Map_Button.Label' | translation }}
        </button>
    }
}

<dialog id="best-seat-available-popover" popover (toggle)="onPopoverToggle()">
    <ng-container *ngTemplateOutlet="bestSeatAvailableTicketSelection"></ng-container>
</dialog>

<ng-template #bestSeatAvailableTicketSelection>
    @for (ticketType of ticketTypes; track ticketType.id) {
        <div class="ticket-type-option">
            <div class="name">{{ ticketType.name }}</div>
            <div class="price">{{ ticketType.getPriceForGroup(section.groupId).price | currency }}</div>
            <div class="amount">
                <button class="secondary decrease"
                        [disabled]="!ticketSelection.has(ticketType.id) || ticketSelection.get(ticketType.id) === 0"
                        (click)="decreaseTicketTypeSelection(ticketType.id)">-
                </button>
                <span>{{ ticketSelection.get(ticketType.id) ?? 0 }}</span>
                <button class="increase"
                        (click)="increaseTicketTypeSelection(ticketType.id)">+
                </button>
            </div>
        </div>
    }

    <button class="submit"
            [disabled]="hasTicketTypesInSelection() ? undefined : true"
            (click)="onSubmit()">{{ 'Ticket_Selection.Best_Seat_Available_Button.Label' | translation }}
    </button>
</ng-template>
