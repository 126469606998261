import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TicketType} from '../api.service';
import {CurrencyPipe, NgTemplateOutlet} from '@angular/common';
import {Section, TicketSelection} from '../../types';
import {TranslationPipe} from '../translation.pipe';
import {SeatMapComponent} from '../seat-map/seat-map.component';

/**
 * This component renders a seat map or a list of ticket types.
 */

@Component({
    selector: 'app-ticket-selection',
    standalone: true,
    imports: [
        CurrencyPipe,
        TranslationPipe,
        NgTemplateOutlet,
        SeatMapComponent
    ],
    templateUrl: './ticket-selection.component.html',
    styleUrl: './ticket-selection.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketSelectionComponent implements OnInit {
    @Input()
    section!: Section;

    @Input()
    ticketTypes: TicketType[] = [];

    @Output()
    select: EventEmitter<TicketSelection> = new EventEmitter();

    public selectionMode: TicketSelectionMode = 'NONE';

    public ticketSelection: TicketSelection = new Map<string, number>();

    ngOnInit(): void {
        // Some check for the bsa/seat map enabled section should happen here
        if (this.section.type === 'CAPACITY') {
            this.selectionMode = 'BEST_SEAT_AVAILABLE';
        }
    }

    public decreaseTicketTypeSelection(ticketTypeId: string): void {
        if (!this.ticketSelection.has(ticketTypeId)) {
            this.ticketSelection.set(ticketTypeId, 0);
            return;
        }

        this.ticketSelection.set(ticketTypeId, Math.max(this.ticketSelection.get(ticketTypeId)! - 1, 0))
    }

    public increaseTicketTypeSelection(ticketTypeId: string): void {
        if (!this.ticketSelection.has(ticketTypeId)) {
            this.ticketSelection.set(ticketTypeId, 1);
            return;
        }

        this.ticketSelection.set(ticketTypeId, this.ticketSelection.get(ticketTypeId)! + 1)
    }

    public hasTicketTypesInSelection(): boolean {
        return [...this.ticketSelection.values()].some(amount => amount > 0);
    }

    public onSubmit(): void {
        this.select.emit(this.ticketSelection);
    }

    public setSelectionMode(selectionMode: TicketSelectionMode): void {
        this.selectionMode = selectionMode;
    }

    public onPopoverToggle(): void {
        this.ticketSelection = new Map<string, number>();
    }
}

export type TicketSelectionMode = 'BEST_SEAT_AVAILABLE' | 'SEAT_MAP' | 'NONE';
