@if (status === 'SUCCESS') {
    <header><h1>{{ 'Payment_Status.Success_Status.Title' | translation }}</h1></header>
    <p>{{ 'Payment_Status.Success_Status.Body' | translation }}</p>
} @else if (status === 'CANCELLED') {
    <header><h1>{{ 'Payment_Status.Cancelled_Status.Title' | translation }}</h1></header>
    <p>{{ 'Payment_Status.Cancelled_Status.Body' | translation }}</p>
    <div class="row">
        <button (click)="onTryAgainClick()">{{ 'Payment_Status.Try_Again_Button.Label' | translation }}</button>
        <span></span>
    </div>
} @else {
    <header><h1>{{ 'Payment_Status.Failed_Status.Title' | translation }}</h1></header>
    <p>{{ 'Payment_Status.Failed_Status.Body' | translation }}</p>
    <div class="row">
        <button (click)="onTryAgainClick()">{{ 'Payment_Status.Try_Again_Button.Label' | translation }}</button>
        <span></span>
    </div>
}


