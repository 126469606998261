import {
    APP_INITIALIZER,
    ApplicationConfig,
    DEFAULT_CURRENCY_CODE,
    LOCALE_ID,
    provideZoneChangeDetection
} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideHttpClient, withInterceptors} from '@angular/common/http';
import {registerLocaleData} from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import localeEN from '@angular/common/locales/en';
import {TranslationService} from './translation/translation.service';
import {AppService} from './app.service';
import {requestInterceptor} from '../request-interceptor';

registerLocaleData(localeNL, 'NL');
registerLocaleData(localeEN, 'EN');


export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({eventCoalescing: true}),
        provideRouter(routes),
        provideHttpClient(
            withInterceptors([requestInterceptor])
        ),
        {
            provide: DEFAULT_CURRENCY_CODE,
            multi: false,
            deps: [AppService],
            useFactory: (app: AppService) => {
                const formattedCurrency = (0).toLocaleString(undefined, {
                    style: 'currency',
                    currency: (app.shop.currencyId ?? 'EUR')
                });
                const match = formattedCurrency.match(/\p{Sc}/u);
                return match ? match[0] : ''
            }
        },
        {
            provide: LOCALE_ID,
            useFactory: () => {
                const supportedLanguageIds = ['NL', 'EN'];
                const preferredLanguageId = navigator.languages[0].split('-')[0].toUpperCase();

                if (supportedLanguageIds.includes(preferredLanguageId)) {
                    return preferredLanguageId
                }

                return 'EN';
            }
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [TranslationService],
            useFactory: (translationService: TranslationService) => {
                return () => {
                    return translationService.getTranslationsFromApi();
                }
            }
        }
    ]
};
