import {CanDeactivateFn} from '@angular/router';
import {inject} from '@angular/core';
import {AppService} from './app.service';
import {TranslationService} from './translation/translation.service';

export const canLeaveCheckoutGuard: CanDeactivateFn<unknown> = (component, currentRoute, currentState, nextState) => {
    const orderId = window.localStorage.getItem('orderId');

    if (!orderId) {
        return true;
    }

    const app = inject(AppService);

    if (nextState.url.includes('checkout/status')) {
        return true;
    }

    const translationService = inject(TranslationService);
    const translation = translationService.getTranslation('Checkout.Leave_Page_Dialog.Body')

    if (confirm(translation)) {
        app.clearOrder();
        return true;
    }

    return false;
};
