import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {AppService} from './app.service';
import {TranslationPipe} from './translation.pipe';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, TranslationPipe],
    templateUrl: './app.component.html',
    styleUrl: './app.component.css'
})
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild('dialog')
    dialogElement!: ElementRef;

    private dialog!: HTMLDialogElement;

    constructor(private readonly app: AppService, private readonly router: Router) {
    }

    ngOnInit(): void {
        this.app.dialog$.subscribe((dialog: any) => {
            if (dialog.title) {
                this.dialog.setAttribute('data-title', dialog.title)
            }

            if (dialog.body) {
                this.dialog.setAttribute('data-body', dialog.body)
            }

            if (dialog.errorCode) {
                this.dialog.setAttribute('data-error-code', dialog.errorCode)
            }

            this.dialog.showModal();
        });

        this.router.events.subscribe({
            next: () => {
                this.dialog.close();
            }
        });
    }

    ngAfterViewInit(): void {
        this.dialog = this.dialogElement.nativeElement;
    }

    public onDialogClose(): void {
        this.dialog.removeAttribute('data-title');
        this.dialog.removeAttribute('data-body');
        this.dialog.removeAttribute('data-error-code');
    }
}
