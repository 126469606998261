import {ActivatedRouteSnapshot, Routes} from '@angular/router';
import {IndexComponent} from './index/index.component';
import {EventComponent} from './event/event.component';
import {CheckoutComponent} from './checkout/checkout.component';
import {canLeaveCheckoutGuard} from './can-leave-checkout.guard';
import {canEnterCheckoutGuard} from './can-enter-checkout.guard';
import {StatusNotFoundComponent} from './status-not-found/status-not-found.component';
import {inject} from '@angular/core';
import {ApiService} from './api.service';
import {AppService} from './app.service';
import {switchMap, tap} from 'rxjs';
import {PaymentStatusComponent} from './payment-status/payment-status.component';
import {Shop} from '../types';

export const routes: Routes = [
    {
        path: '',
        component: StatusNotFoundComponent
    },
    {
        path: ':shopSlug',
        resolve: {
            shop: (route: ActivatedRouteSnapshot) => {
                const api: ApiService = inject(ApiService);
                const app: AppService = inject(AppService);

                return api.getShop(route.params['shopSlug']).pipe(
                    tap((shop: Shop) => {
                        app.shop = shop;
                        app.initializeStyle();
                    }),
                    switchMap((shop: Shop) => {
                        return api.getInSale(shop.id).pipe(
                            tap((events) => {
                                app.shop.events.forEach((event) => event.status = events[event.id] ? 'AVAILABLE' : 'UNAVAILABLE');
                            })
                        );
                    })
                );
            }
        },
        children: [
            {
                path: 'event/:slug',
                component: EventComponent,
            },
            {
                path: 'checkout',
                component: CheckoutComponent,
                canActivate: [canEnterCheckoutGuard],
                canDeactivate: [canLeaveCheckoutGuard],
            },
			{
		        path: 'checkout/status/:status/:paymentId',
        		component: PaymentStatusComponent,
			},
            {
                path: '',
                component: IndexComponent,
            },
        ]
    }
];
