import {Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, map, Observable, Subject, switchMap, tap} from 'rxjs';
import {Router} from '@angular/router';
import {ApiService, TicketType} from './api.service';
import {environment} from '../environments/environment';
import {Event, Ring, Section, Shop, Venue} from '../types';
import {Title} from '@angular/platform-browser';
import {TranslationService} from './translation/translation.service';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    public shop!: Shop;

    private reservationSubject: BehaviorSubject<Reservation> = new BehaviorSubject({items: ([] as any[])});
    private reservation: Reservation = {
        items: []
    }

    public event!: Event;
    public venueId!: string;

    private dialogSubject: Subject<any> = new Subject();
    public dialog$ = this.dialogSubject.asObservable();

    constructor(private api: ApiService,
                private router: Router,
                private readonly titleService: Title,
                private readonly translationService: TranslationService) {
    }

    public getEvent(slug: string): any {
        return this.shop.events.find((event: any) => event.slug === slug);
    }

    public getReservation$(): Observable<Reservation> {
        return this.reservationSubject.asObservable();
    }

    public addToReservation(e: any): void {
        this.reservation.items.push(e);
        this.emitReservationUpdate();
    }

    public clearReservation(): void {
        this.reservation.items = [];
        this.emitReservationUpdate();
    }

    public finishReservation(): void {
        if (this.reservation.items.length === 0) {
            return;
        }

        this.api.postOrder().pipe(
            tap(() => {
                this.showDialog('Finish_Reservation.Pending_Dialog.Body');
            }),
            switchMap(order => {
                if (this.reservation.items[0].venueSectionId) {
                    const postOrderItemBody = this.reservation.items.map(item => {
                        if (this.event.type === 'EVENT') {
                            return {
                                ticket: {
                                    eventId: item.eventId,
                                    ticketTypeId: item.ticketTypeId
                                }
                            };
                        } else if (this.event.type === 'SUBSCRIPTION_TYPE') {
                            return {
                                subscription: {
                                    subscriptionTypeId: item.eventId,
                                    subscriptionTypePriceId: item.ticketTypeId
                                }
                            }
                        }

                        return;
                    });

                    const venueSectionIds: Set<string> = new Set();
                    this.reservation.items.forEach((item: any) => {
                        venueSectionIds.add(item.venueSectionId);
                    });

                    return this.api.postOrderItemsBestSeatAvailable(order.id, {
                        orderItems: postOrderItemBody,
                        venueSectionIds: Array.from(venueSectionIds)
                    });
                } else {
                    const postOrderItemBody = this.reservation.items.map(item => {
                        return {
                            ticket: {
                                eventId: item.eventId,
                                venueSectionSeatId: item.seatId,
                                ticketTypeId: item.ticketTypeId
                            }
                        }
                    });

                    return this.api.postOrderItems(order.id, postOrderItemBody);
                }
            }),
            tap((orderItems: any[]) => {
                window.localStorage.setItem('orderId', orderItems[0].orderId);
            })
        ).subscribe({
            error: (error: any) => {
                this.clearReservation();
                this.showErrorDialog(error.error.code);
            },
            complete: () => {
                void this.router.navigate([this.shop.slug, 'checkout']);
            }
        });
    }

    private emitReservationUpdate(): void {
        this.reservationSubject.next(this.reservation);
    }

    public finishOrder(checkoutForm: any): void {
        const orderId = window.localStorage.getItem('orderId');

        if (!orderId) {
            return;
        }

        this.showDialog('Finish_Order.Pending_Dialog.Body')

        const finishOrderBody = {
            orderId: orderId,
            customer: {
                firstName: checkoutForm.customerData.firstName,
                lastName: checkoutForm.customerData.lastName,
                email: checkoutForm.customerData.email,
                phoneNumber: checkoutForm.customerData.phone,
            },
            acceptedConsentIds: this.getAcceptedConsentIds(checkoutForm.consents),
            paymentMethod: {
                method: checkoutForm.payment.method,
                issuerId: checkoutForm.payment.issuer,
            },
            callbackUrls: {
                success: `${environment.APP_URL}/${this.shop.slug}/checkout/status/success/:paymentId`,
                canceled: `${environment.APP_URL}/${this.shop.slug}/checkout/status/canceled/:paymentId`,
                expired: `${environment.APP_URL}/${this.shop.slug}/checkout/status/expired/:paymentId`,
                failed: `${environment.APP_URL}/${this.shop.slug}/checkout/status/failed/:paymentId`
            }
        }

        this.api.postPayment(finishOrderBody).subscribe({
            next: (payment: any) => {
                if (payment.paymentUrl) {
                    window.top!.location.href = payment.paymentUrl;
                } else {
                    window.localStorage.removeItem('orderId');
                    void this.router.navigate([this.shop.slug, 'checkout', 'status', 'success', payment.id]);
                }
            },
            error: (error) => {
                this.showErrorDialog(error.error.code);
            }
        });
    }

    public clearOrder(): any {
        const orderId = window.localStorage.getItem('orderId');

        if (!orderId) {
            return;
        }

        this.api.deleteOrder(orderId).subscribe(() => {
            window.localStorage.removeItem('orderId');
        });
    }

    public initializeStyle(): void {
        document.querySelector('html')?.classList.add(this.shop.style.theme.toLowerCase());

        document.documentElement.style.setProperty('--accent-color', this.shop.style.primary);

        const luminance = this.getLuminance(this.shop.style.primary);
        const textColor = luminance > 0.5 ? '#111' : '#fff';
        document.documentElement.style.setProperty('--button-text-color', textColor);

        if (this.shop.style.backgroundImage) {
            document.documentElement.style.setProperty('--background-image', `url(${this.shop.style.backgroundImage})`);
        }

        this.titleService.setTitle(`${this.shop.name} - CM.com`);

        if (this.shop.supportUrl) {
            document.getElementById('support-link')?.setAttribute('href', this.shop.supportUrl);
        } else {
            document.getElementById('support-link')?.remove();
        }
    }

    private getLuminance(color: string): number {
        const rgb = color.match(/\d+/g)?.map(Number);

        if (!rgb) {
            return 0;
        }

        const [r, g, b] = rgb.map(c => {
            c /= 255;
            return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
        });

        return 0.2126 * r + 0.7152 * g + 0.0722 * b;
    }

    private getAcceptedConsentIds(consents: any): string[] {
        const acceptedConsentIds = [];

        for (const key in consents) {
            if (consents[key] === true) {
                acceptedConsentIds.push(key);
            }
        }

        return acceptedConsentIds;
    }

    public getVenue(): Observable<Venue> {
        return this.api.getVenue(this.event.venue.id, this.event.id, this.event.type).pipe(
            switchMap((venue: Venue) => {
                return this.api.getSectionStatus(venue.id, this.event.id, this.event.type).pipe(
                    map((status: any[]) => {
                        const rings = venue.rings?.map((ring: Ring) => {
                            const sections: Section[] = [];

                            status.forEach((sectionStatus: any) => {
                                const section = ring.sections.find((section: Section) => section.id === sectionStatus.id);

                                if (section) {
                                    section.state = (sectionStatus.status === 'SOLD_OUT') ? 'SOLD_OUT' : 'AVAILABLE';
                                    sections.push(section);
                                }
                            });

                            return {...ring, sections}
                        });

                        return {...venue, rings}
                    })
                );
            }),
            tap((venue: any) => {
                this.venueId = venue.id;
            })
        );
    }

    public getTicketTypes(): Observable<TicketType[]> {
        return this.api.getTicketTypes(this.event.id, this.event.type);
    }

    public setEvent(event: any): void {
        this.event = event;
    }

    public removeReservationSeat(e: any): void {
        const index = this.reservation.items.findIndex((item: any) => item.eventId === e.eventId && item.seatId === e.seatId);

        if (index === -1) {
            return;
        }

        this.reservation.items.splice(index, 1);
        this.emitReservationUpdate();
    }

    public applyDiscountToOrder(code: string): Observable<any> {
        const orderId = window.localStorage.getItem('orderId');

        if (!orderId) {
            return EMPTY;
        }

        return this.api.postDiscount(orderId, code);
    }

    public showDialog(body: string, title?: string, errorCode?: string): void {
        this.dialogSubject.next({
            title, body, errorCode
        });
    }

    public showErrorDialog(errorCode: string): void {
        const title = this.translationService.hasTranslation(`Dialog.Error.Code_${errorCode}.Title`)
            ? `Dialog.Error.Code_${errorCode}.Title`
            : 'Dialog.Error.Generic.Title';

        const body = this.translationService.hasTranslation(`Dialog.Error.Code_${errorCode}.Body`)
            ? `Dialog.Error.Code_${errorCode}.Body`
            : 'Dialog.Error.Generic.Body';

        this.showDialog(body, title, errorCode)
    }
}

export type Reservation = {
    items: any[];
}
