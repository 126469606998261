import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Section, Venue} from '../../types';
import {TranslationPipe} from '../translation.pipe';

/**
 * This component renders either a venue map or a list of sections. It's only
 * responsibility is to allow the user to select a section
 */

@Component({
    selector: 'app-section-selection',
    standalone: true,
    imports: [
        TranslationPipe
    ],
    templateUrl: './section-selection.component.html',
    styleUrl: './section-selection.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionSelectionComponent {
    @Input()
    venue!: Venue;

    @Output()
    select: EventEmitter<Section> = new EventEmitter();

    public onSectionSelect(section: Section) {
        this.select.emit(section);
    }
}
